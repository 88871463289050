<template>
	<div>
		<Promo             
			msg="PÁGINA NO <br> ENCONTRADA"
            btn="IR AL INICIO"
			@promoClicked="goToHome"
		/>
	
	</div>
</template>

<script>
	import Promo from "../components/Promo"
	export default {
		name: 'NotFound',
		components: {
			Promo,
		},
		methods: {			
			goToHome() {
				this.$router.push({name: 'Home'})
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>